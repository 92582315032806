<template>
  <div class="chinaMap"></div>
</template>

<script>
import 'echarts/map/js/china.js'

export default {
  name: '',
  data() {
    return {}
  },
  methods: {
    setMap() {
      const chinaDatas = [
        [
          {
            name: '秦皇岛市',
            value: 150
          }
        ],
        [
          {
            name: '黑龙江',
            value: 279
          }
        ],
        [
          {
            name: '内蒙古',
            value: 125
          }
        ],
        [
          {
            name: '吉林',
            value: 0
          }
        ],
        [
          {
            name: '辽宁',
            value: 0
          }
        ],
        [
          {
            name: '河北',
            value: 193
          }
        ],
        [
          {
            name: '天津',
            value: 0
          }
        ],
        [
          {
            name: '山西',
            value: 0
          }
        ],
        [
          {
            name: '陕西',
            value: 0
          }
        ],
        [
          {
            name: '甘肃',
            value: 238
          }
        ],
        [
          {
            name: '宁夏',
            value: 0
          }
        ],
        [
          {
            name: '青海',
            value: 0
          }
        ],
        [
          {
            name: '新疆',
            value: 272
          }
        ],
        [
          {
            name: '西藏',
            value: 0
          }
        ],
        [
          {
            name: '四川',
            value: 0
          }
        ],
        [
          {
            name: '重庆',
            value: 0
          }
        ],
        [
          {
            name: '山东',
            value: 194
          }
        ],
        [
          {
            name: '河南',
            value: 0
          }
        ],
        [
          {
            name: '江苏',
            value: 0
          }
        ],
        [
          {
            name: '安徽',
            value: 229
          }
        ],
        [
          {
            name: '湖北',
            value: 0
          }
        ],
        [
          {
            name: '浙江',
            value: 0
          }
        ],
        [
          {
            name: '福建',
            value: 0
          }
        ],
        [
          {
            name: '江西',
            value: 0
          }
        ],
        [
          {
            name: '湖南',
            value: 175
          }
        ],
        [
          {
            name: '贵州',
            value: 177
          }
        ],
        [
          {
            name: '广西',
            value: 0
          }
        ],
        [
          {
            name: '海南',
            value: 0
          }
        ],
        [
          {
            name: '上海',
            value: 1
          }
        ]
        /* {name: '鄂尔多斯', value: 125},
            {name: '广州', value: 238},
            {name: '贵阳', value: 171},
            {name: '宝鸡', value: 272},
            {name: '长沙', value: 175},
            {name: '衢州', value: 177},
            {name: '廊坊', value: 193},
            {name: '菏泽', value: 194},
            {name: '合肥', value: 229},
            {name: '大庆', value: 279}*/
      ]
      const chinaGeoCoordMap = {
        黑龙江: [127.9688, 45.368],
        内蒙古: [110.3467, 41.4899],
        吉林: [125.8154, 44.2584],
        北京市: [116.4551, 40.2539],
        辽宁: [123.1238, 42.1216],
        河北: [114.4995, 38.1006],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        陕西: [109.1162, 34.2004],
        甘肃: [103.5901, 36.3043],
        宁夏: [106.3586, 38.1775],
        青海: [101.4038, 36.8207],
        新疆: [87.9236, 43.5883],
        西藏: [91.11, 29.97],
        四川: [103.9526, 30.7617],
        重庆: [108.384366, 30.439702],
        山东: [117.1582, 36.8701],
        河南: [113.4668, 34.6234],
        江苏: [118.8062, 31.9208],
        安徽: [117.29, 32.0581],
        湖北: [114.3896, 30.6628],
        浙江: [119.5313, 29.8773],
        福建: [119.4543, 25.9222],
        江西: [116.0046, 28.6633],
        湖南: [113.0823, 28.2568],
        贵州: [106.6992, 26.7682],
        云南: [102.9199, 25.4663],
        广东: [113.12244, 23.009505],
        广西: [108.479, 23.1152],
        海南: [110.3893, 19.8516],
        上海: [121.4648, 31.2891],
        秦皇岛市: [119.596224, 39.943458]
      }

      const convertData = function (data) {
        const res = []
        for (let i = 0; i < data.length; i++) {
          const dataItem = data[i]
          const fromCoord = chinaGeoCoordMap[dataItem[0].name]
          const toCoord = [119.596224, 39.943458] //中心点地理坐标
          if (fromCoord && toCoord) {
            res.push([
              {
                coord: fromCoord,
                value: dataItem[0].value
              },
              {
                coord: toCoord
              }
            ])
          }
        }
        return res
      }
      const series = []
      ;[['秦皇岛市', chinaDatas]].forEach(function (item) {
        // console.log(item);
        series.push(
          {
            type: 'lines',
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              symbolSize: 8 //图标大小
            },
            lineStyle: {
              normal: {
                width: 1, //尾迹线条宽度
                opacity: 1, //尾迹线条透明度
                curveness: 0.3 //尾迹线条曲直度
              }
            },
            data: convertData(item[1])
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: 'stroke', //波纹绘制方式 stroke, fill
              scale: 4 //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: true,
                position: 'right', //显示位置
                offset: [5, 0], //偏移设置
                formatter: function (params) {
                  //圆环显示文字
                  return params.data.name
                },
                fontSize: 13
              },
              emphasis: {
                show: true
              }
            },
            symbol: 'circle',
            symbolSize: function () {
              /*return 5+ val[2] * 5; //圆环大小*/
              return 5
            },
            itemStyle: {
              normal: {
                show: false,
                color: '#f00'
              }
            },
            data: item[1].map(function (dataItem) {
              return {
                //在这里定义你所要展示的数据
                name: dataItem[0].name,
                value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value])
              }
            })
          },
          //中心点
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            zlevel: 10,
            rippleEffect: {
              period: 4,
              brushType: 'stroke',
              scale: 4
            },
            label: {
              normal: {
                show: true,
                //offset:[5, 0],
                color: '#0f0',
                formatter: '{b}',
                textStyle: {
                  color: '#3de7c9',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              },
              emphasis: {
                show: true,
                color: '#f60'
              },
              shadowColor: '#013954'
            },
            symbol: 'pin',
            symbolSize: 80,
            data: [
              {
                name: item[0],
                value: chinaGeoCoordMap[item[0]].concat([10])
              }
            ]
          }
        )
      })
      let option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(166, 200, 76, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0
          /* formatter: function(params) {
                  //根据业务自己拓展要显示的内容
                  let res = "";
                  const name = params.name;
                  const value = params.value[params.seriesIndex + 1];
                  res = "<span style='color:#fff;'>" + name + "</span><br/>数据：" + value;
                  return res;
                }*/
        },
        /*  backgroundColor:"#013954",*/
        visualMap: {
          //图例值控制
          min: 0,
          max: 1,
          calculable: true,
          show: true,
          color: ['#f44336', '#fc9700', '#ffde00', '#ffde00', '#00eaff'],
          textStyle: {
            color: '#fff'
          }
        },
        geo: {
          map: 'china',
          zoom: 1.2,
          label: {
            emphasis: {
              show: false
            }
          },
          roam: true, //是否允许缩放
          itemStyle: {
            normal: {
              color: 'rgba(51, 69, 89, .5)', //地图背景色
              borderColor: '#516a89', //省市边界线00fcff 516a89
              borderWidth: 1
            },
            emphasis: {
              color: 'rgba(37, 43, 61, .5)' //悬浮背景
            }
          }
        },
        series: series
      }

      let myChart = this.$echarts(this.$el)
      /*   let that = this
          var index = 0; //播放所在下标
          this.timer = setInterval(fn, this.strTime);
          //缩放放大、拖拽停止循环
          myChart.on('georoam', function () {
            clearInterval(that.timer)
            index = 0
          })
          //鼠标移出执行
          myChart.on('mouseout', function () {
            that.timer = setInterval(fn, that.strTime);
          })
          //鼠标移入停止
          myChart.on('mouseover', function(){
            clearInterval(that.timer)
            index = 0
          })
          function fn() {
            option.series[2].data = [option.series[1].data[index]]
            option.series[2].rippleEffect = {
              color: '#00FF00',
              scale: 6
            }
            myChart.setOption(option, true);
            myChart.dispatchAction({
              type: 'showTip',
              seriesIndex: 1,
              dataIndex: index
            });
            index++;
            //判断数据是否播放完，然后从0重新播放
            if (index >= option.series[1].data.length) {
              index = 0;
            }
          }*/
      myChart.clear()
      myChart.resize()
      myChart.setOption(option)
    }
  },
  mounted() {
    this.setMap()
  }
}
</script>

<style lang="less" scoped>
.chinaMap {
  height: 100%;
}
</style>
